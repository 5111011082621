import { useEffect, useState } from 'react'
import { GoogleReCaptcha, GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link, useNavigate } from 'react-router-dom'
import { app } from '../../helpers/app'
import { changeDocumentTitle } from '../../helpers/helpers'
import useAlert from '../../hooks/useAlert'
import useAuth from '../../hooks/useAuth'
import usePost from '../../hooks/usePost'
import { DashboardRoutes } from '../../routes/RouteNames'
import AlertSeverity from '../alerts/AlertSeverity'
import Button from '../buttons/button1/Button'
import Input from '../inputs/input1/Input'

function Signup() {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Create a new account')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    // const [password_confirm, setPasswordConfirm] = useState('')
    const { response, isLoading, error, post } = usePost()
    const { response: captchaRes, isLoading: captchaIsLoading, error: captchaError, post: captchaPost } = usePost()
    const { type: alertType, visible: alertVisible, message: alertMessage, show: showAlert, hide: hideAlert } = useAlert()
    const {login} = useAuth()
    const navigate = useNavigate()
    const [captchaToken, setCaptchaToken] = useState('')

    useEffect(() => {
        hideAlert()

        if (error) {
            showAlert('danger', error.message)
        }

        if (response) {
            const data = response.data
            // showAlert('success', 'Your account has been created, now go to your email inbox to complete verfication!')
            navigate(DashboardRoutes().VERIFY_EMAIL.nav(data.uuid))
        }

    }, [error, response])
    
    useEffect(() => {

        if ( captchaError )
        {
            showAlert('danger', "Please verify that you're not a robot!")
        }

        if ( captchaRes )
        {
            submit()
        }

    }, [captchaError, captchaRes])

    const submit = () => {
        hideAlert()
        // check inputs
        if (!name) {
            showAlert('danger', 'Please enter your full name.')
            return
        }

        if (!username) {
            showAlert('danger', 'Please enter your username.')
            return
        }

        if (!email) {
            showAlert('danger', 'Please enter your email.')
            return
        }

        if (!password) {
            showAlert('danger', 'Please enter your password.')
            return
        }

        post('/signup', {
            name,
            email,
            username,
            password,
        }, {
            'X-TOKEN': app().TOKEN
        })
    }

    const resetForm = () => {
        setName('')
        setEmail('')
        setUsername('')
        setPassword('')
    }

    const verifyCaptcha = (e) => {
        e.preventDefault()

        captchaPost('/google-recaptcha/siteverify', {
            response: captchaToken,
        })
    }
    
    return (
        <GoogleReCaptchaProvider reCaptchaKey={app().GRECAPTCHA_V3.SITE_KEY}>
        <div className='flex items-center justify-center h-full'>

            <div className="text-center max-w-[678px] w-full mx-auto px-[56px] py-[44px]">

                <h2 className="text-[28px] leading-8 mb-8">Create a new account</h2>

                <p className='max-w-sm mx-auto font-light'>
                    Fill in this form to create your new account.
                </p>

                <div className="my-3">
                    <AlertSeverity type={alertType} visible={alertVisible} message={alertMessage} onClickHide={hideAlert} />
                </div>

                <div className="inline-block min-w-[270px] mt-12">

                    <form onSubmit={verifyCaptcha}>

                        <div className="grid grid-cols-2">

                            <div className="mb-7 border-b border-slate-950">
                                <label htmlFor="" className='text-[13px] mb-5 block'>Your full name</label>
                                <Input value={name} onChange={(e) => setName(e.target.value)} />
                            </div>

                            <div className="mb-7 border-b border-slate-950">
                                <label htmlFor="" className='text-[13px] mb-5 block'>Your username</label>
                                <Input value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>

                        </div>

                        <div className="mb-7 border-b border-slate-950">
                            <label htmlFor="" className='text-[13px] mb-5 block'>Your email</label>
                            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="grid grid-cols-0">

                            <div className="mb-7 border-b border-slate-950">
                                <label htmlFor="" className='text-[13px] mb-5 block'>Your secret password</label>
                                <Input type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>

                            {/* <div className="mb-7 border-b border-slate-950">
                                <label htmlFor="" className='text-[13px] mb-5 block'>Confirm password</label>
                                <Input type={'password'} value={password_confirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                            </div> */}

                        </div>

                        <div className="mt-10">
                            <Button isLoaderVisible={isLoading} disabled={isLoading || !captchaToken} />
                        </div>

                        <div className="mt-5">
                            <GoogleReCaptcha onVerify={setCaptchaToken}/>
                        </div>

                        <div className="mt-5">
                            <span className='inline-block pr-2 text-primary'>Already have an account?</span>
                            <Link to={DashboardRoutes().LOGIN.nav} className={'text-sixth font-bold'}>
                                Sign in
                            </Link>
                        </div>

                    </form>

                </div>

            </div>

        </div>
        </GoogleReCaptchaProvider>
    )
}

export default Signup
