import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import { AuthRoutes, UserDashboardRoutes } from '../../../routes/RouteNames'
import UserPlaceholder from '../../../assets/img/male.png'
import Links from '../../../components/links/footer-links/links1/Links'
import ProfileImage from '../../../components/profile/images/image1/ProfileImage'
const Sidebar = () => {

    const { Auth } = useAuth()

    return (
        <div className='flex flex-col justify-between h-full'>

            <div className="mt-[40px]">

                <div className="mb-4">
                    <Link to={AuthRoutes().USER.PROFILE}>
                        <ProfileImage url={ (Auth().user?.avatar_urls?.sizes) ? Auth().user?.avatar_urls?.sizes['88x88']: null } classes={''} />
                    </Link>
                </div>

                <div className="mb-4">
                    <Link to={AuthRoutes().USER.PROFILE}>
                        <h2 className='text-primary font-semibold'>{ Auth().user.name }</h2>
                    </Link>
                </div>

                <div className="mb-[24px]">
                    <p className="text-xs text-secondary leading-5">
                        {Auth().user.bio}
                    </p>
                </div>

                <div className="">
                    <Link to={UserDashboardRoutes().USER.SETTINGS.nav()} className="text-third hover:text-[#191919] text-xs">
                        Edit profile
                    </Link>
                </div>

            </div>

            <div className="mt-[40px]"></div>

            <div className="py-[24px]">

                <Links/>

            </div>

        </div>
    )
}

export default Sidebar
