import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserDashboardRoutes } from '../../../routes/RouteNames'
import './TabList.css'
import useFetch from '../../../hooks/useFetch'
import { abbreviateNumber } from '../../../helpers/helpers'
import useAuth from '../../../hooks/useAuth'
const TabList = () => {
    const { Auth } = useAuth()
    const tabsRef = useRef()
    const {response, error, isLoading, fetch} = useFetch()
    const [totalDrafts, setTotalDrafts] = useState(0)
    const [totalPublished, setTotalPublished] = useState(0)
    const [totalResponses, setTotalResponses] = useState(0)
    // const [totalResponses, setTotalResponses] = useState(0)

    useEffect(() =>
    {

        if ( window.location.pathname == UserDashboardRoutes().STORIES.DRAFTS.path )
        {
            triggerTabChange('drafts')
        }
        else if ( window.location.pathname == UserDashboardRoutes().STORIES.PUBLIC.path )
        {
            triggerTabChange('published')
        }
        else if ( window.location.pathname == UserDashboardRoutes().STORIES.RESPONSES.INDEX.path )
        {
            triggerTabChange('responses')
        }

    }, [window.location.pathname])

    useEffect(() =>
    {

        fetch(`/dashboard/all/countAll/${Auth().user.id}`)

    }, [])

    useEffect(() => 
    {

        // if ( error )
        // {
        //     // ignore
        //     console.log(error)
        // }

        if ( response )
        {
            const data = response.data

            setTotalDrafts(data.stories.draft)
            setTotalPublished(data.stories.publish)
            setTotalResponses(data.comments)
        }
        
    }, [response, error])

    const handleTabChange = (e) =>
    {
        const target = e.target
        const tab = target.closest('[role="tab"]')
        const tabs = tab.closest('[role="tabs"]')

        for(var i = 0; i < tabs.children.length; i++)
        {
            if ( tabs.children[i].getAttribute('role') )
            {
                const tab = tabs.children[i]
            
                tab.classList.remove('active')
            }
            
        }

        tab.classList.add('active')
    }

    const triggerTabChange = (tab) =>
    {
        const children = tabsRef.current.children

        for (var i = 0; i < children.length; i++)
        {
            const child = children[i]

            if ( !child.getAttribute('role') ) continue

            const tabName = child.dataset.name
            
            child.classList.remove('active')

            if ( tabName == tab )
            {
                child.classList.add('active')
            }
        }
    }
    
    return (
        <div>

            <div className="flex border-b border-[#f2f2f2] mb-9" role={'tabs'} ref={tabsRef}>

                <div className="pb-4 border-b border-transparent tab active" role={'tab'} data-name="drafts">
                    <Link to={UserDashboardRoutes().STORIES.DRAFTS.nav()} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Drafs</div>
                        <div className="w-1"></div>
                        <div>{abbreviateNumber(totalDrafts)}</div>
                    </Link>
                </div>

                <div className="w-8"></div>

                <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="published">
                    <Link to={UserDashboardRoutes().STORIES.PUBLIC.nav()} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Published</div>
                        <div className="w-1"></div>
                        <div>{abbreviateNumber(totalPublished)}</div>
                    </Link>
                </div>

                <div className="w-8"></div>

                <div className="pb-4 border-b border-transparent tab" role={'tab'} data-name="responses">
                    <Link to={UserDashboardRoutes().STORIES.RESPONSES.INDEX.nav()} className='text-sm flex tab-link' onClick={handleTabChange}>
                        <div>Responses</div>
                        <div className="w-1"></div>
                        <div>{ abbreviateNumber(totalResponses) }</div>
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default TabList
