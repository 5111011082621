const LinkInfo = ({
    onClick,
    disabled = false,
    classes,
    children,
    type = 'button'
}) => {
    return (
        <div>
            <button
                type={type}
                className={`text-cyan-600 border border-transparent text-sm leading-[20px] ${classes}`}
                onClick={onClick}
                disabled={disabled}
                >
                {children}
                
            </button>
        </div>
    )
}

export default LinkInfo
