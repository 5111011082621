import React from 'react'
import { Link } from 'react-router-dom'

const Links = () => {

    return (
        <div>

            <ul className="list-none flex flex-wrap gap-x-2">
                <li className="">
                    <a href='https://pondstories.com/cookie-policy' target={'_blank'} className='text-[11px] text-secondary'>
                        Cookie
                    </a>
                </li>
                <li className="">
                    <a href='https://pondstories.com/contact' target={'_blank'} className='text-[11px] text-secondary'>
                        Contact Us
                    </a>
                </li>
                <li className="">
                    <a href='https://pondstories.com/about' target={'_blank'} className='text-[11px] text-secondary'>
                        About
                    </a>
                </li>
                <li className="">
                    <a href='https://pondstories.com/privacy-policy' target={'_blank'} className='text-[11px] text-secondary'>
                        Privacy
                    </a>
                </li>
                <li className="">
                    <a href='https://pondstories.com/terms-of-service' target={'_blank'} className='text-[11px] text-secondary'>
                        Terms
                    </a>
                </li>
                <li className="">
                    <a href='https://pondstories.com/text-to-speech' target={'_blank'} className='text-[11px] text-secondary'>
                        Text to speech
                    </a>
                </li>
            </ul>

        </div>
    )
}

export default Links
