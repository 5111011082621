import { useEffect, useState } from "react"
import useFetch from "../../../hooks/useFetch"
import Placeholder from '../../../components/placeholders/placeholder1/Placeholder'
import { abbreviateNumber, formatReadTime, getDatetimeDifference, summarizeText } from "../../../helpers/helpers"
import useAuth from "../../../hooks/useAuth"
const StoriesTrendingList = ({
    total,
}) => {
    const { Auth } = useAuth()
    const { response, error, isLoading, fetch } = useFetch()
    const [stories, setStories] = useState(null)

    useEffect(() => {

        // if ( error )
        // {
        //     console.log(error)
        // }

        if (response) {
            setStories(response.data)
            // console.log(response.data)
        }

    }, [response, error])

    useEffect(() => {

        fetch(`/dashboard/stories/trending/${total}/${Auth().user.id}`)

    }, [])

    return (
        <div>

            {
                isLoading && Array.from([1, 2, 3, 4]).map((v, k) => {
                    return (
                        <div className="mb-4" key={k}>

                            <div className="mb-3">
                                <Placeholder className={'!block h-3 !rounded-sm'} />
                            </div>

                            <div className="flex items-center">

                                <div className="">
                                    <Placeholder className={'w-10 h-1 !rounded-sm'} />
                                </div>

                            </div>

                        </div>
                    )
                })
            }

            {
                stories && stories.map((story, storyIndex) => {
                    return (
                        <div className="mb-3" key={story.id}>

                            <div className="mb-3">
                                <a href={story.urls.canonical} target={'_blank'}>
                                    <h2 className="text-primary text-md font-bold">
                                        {summarizeText(story.title, 50)}
                                    </h2>
                                </a>
                            </div>

                            <div className="flex items-center text-secondary text-[13px]">

                                <div className="">
                                    {formatReadTime(story.read_time * 60)}
                                </div>

                                <div className="mx-1">.</div>

                                <div className="">
                                    {getDatetimeDifference(story.created_at).auto}
                                </div>

                                <div className="mx-1">.</div>

                                <div className="flex items-center">
                                    <div className="mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
                                        </svg>
                                    </div>
                                    <div className="">
                                        {abbreviateNumber(story.tota_views)}
                                    </div>
                                </div>

                                <div className="mx-1">.</div>

                                <div className="flex items-center">
                                    <div className="mr-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill='currentColor' aria-label="clap">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M11.37.83L12 3.28l.63-2.45h-1.26zM13.92 3.95l1.52-2.1-1.18-.4-.34 2.5zM8.59 1.84l1.52 2.11-.34-2.5-1.18.4zM18.52 18.92a4.23 4.23 0 0 1-2.62 1.33l.41-.37c2.39-2.4 2.86-4.95 1.4-7.63l-.91-1.6-.8-1.67c-.25-.56-.19-.98.21-1.29a.7.7 0 0 1 .55-.13c.28.05.54.23.72.5l2.37 4.16c.97 1.62 1.14 4.23-1.33 6.7zm-11-.44l-4.15-4.15a.83.83 0 0 1 1.17-1.17l2.16 2.16a.37.37 0 0 0 .51-.52l-2.15-2.16L3.6 11.2a.83.83 0 0 1 1.17-1.17l3.43 3.44a.36.36 0 0 0 .52 0 .36.36 0 0 0 0-.52L5.29 9.51l-.97-.97a.83.83 0 0 1 0-1.16.84.84 0 0 1 1.17 0l.97.97 3.44 3.43a.36.36 0 0 0 .51 0 .37.37 0 0 0 0-.52L6.98 7.83a.82.82 0 0 1-.18-.9.82.82 0 0 1 .76-.51c.22 0 .43.09.58.24l5.8 5.79a.37.37 0 0 0 .58-.42L13.4 9.67c-.26-.56-.2-.98.2-1.29a.7.7 0 0 1 .55-.13c.28.05.55.23.73.5l2.2 3.86c1.3 2.38.87 4.59-1.29 6.75a4.65 4.65 0 0 1-4.19 1.37 7.73 7.73 0 0 1-4.07-2.25zm3.23-12.5l2.12 2.11c-.41.5-.47 1.17-.13 1.9l.22.46-3.52-3.53a.81.81 0 0 1-.1-.36c0-.23.09-.43.24-.59a.85.85 0 0 1 1.17 0zm7.36 1.7a1.86 1.86 0 0 0-1.23-.84 1.44 1.44 0 0 0-1.12.27c-.3.24-.5.55-.58.89-.25-.25-.57-.4-.91-.47-.28-.04-.56 0-.82.1l-2.18-2.18a1.56 1.56 0 0 0-2.2 0c-.2.2-.33.44-.4.7a1.56 1.56 0 0 0-2.63.75 1.6 1.6 0 0 0-2.23-.04 1.56 1.56 0 0 0 0 2.2c-.24.1-.5.24-.72.45a1.56 1.56 0 0 0 0 2.2l.52.52a1.56 1.56 0 0 0-.75 2.61L7 19a8.46 8.46 0 0 0 4.48 2.45 5.18 5.18 0 0 0 3.36-.5 4.89 4.89 0 0 0 4.2-1.51c2.75-2.77 2.54-5.74 1.43-7.59L18.1 7.68z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="">
                                        {abbreviateNumber(story.total_claps)}
                                    </div>
                                </div>

                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default StoriesTrendingList
