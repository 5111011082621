import { Route, Routes } from "react-router-dom"
import Login from "../components/login/Login"
import Signup from "../components/signup/Signup"
import LayoutDashboard from '../layouts/LayoutDashboard'
import { DashboardRoutes } from "./RouteNames"
import { UserDashboardRoutes } from "./RouteNames"
import RequireAuth from '../components/RequireAuth'
import CheckAuth from "../components/CheckAuth"
import UserProfile from "../pages/users/UserProfile"

import StoryIndex from "../pages/stories/StoryIndex"
import StoryDraftsIndex from "../pages/stories/drafts/StoryDraftsIndex"
import StoryPublishedIndex from "../pages/stories/published/StoryPublishedIndex"
import LayoutDashboardClean from "../layouts/LayoutDashboardClean"
import StoryCreate from "../pages/stories/StoryCreate"
import StoryUpdate from "../pages/stories/StoryUpdate"
import UserSettings from "../pages/users/settings/UserSettings"
import UserIndex from "../pages/users/UserIndex"
import StoryShow from "../pages/stories/StoryShow"
import ResponsesIndex from "../pages/stories/responses/ResponsesIndex"
import ResponseShow from "../pages/stories/responses/ResponseShow"
import NotificationsIndex from "../pages/users/activity/NotificationsIndex"
import StatsIndex from "../pages/stats/StatsIndex"
import LayoutDashboardStats from "../layouts/LayoutDashboardStats"
import NotFound from "../pages/NotFound"
import SiteSettings from "../pages/users/settings/SiteSettings"
import VerifyEmail from "../components/verify-email/VerifyEmail"
function WebRoutes() {

    const DASHBOARD_ROUTES = DashboardRoutes()
    const USER_DASHBOARD_ROUTES = UserDashboardRoutes()

    return (
        <>

            <Routes>

                {/* Login */}
                <Route element={<CheckAuth/>} >
                    <Route path={DASHBOARD_ROUTES.LOGIN.path} element={<Login />} ></Route>
                </Route>
                <Route path={DASHBOARD_ROUTES.SIGNUP.path} element={<Signup />} ></Route>
                <Route path={DASHBOARD_ROUTES.VERIFY_EMAIL.path} element={<VerifyEmail />} ></Route>
                {/* Protected */}
                <Route element={<RequireAuth />} >

                    <Route element={<LayoutDashboard />} >
                        <Route path={USER_DASHBOARD_ROUTES.USER.PROFILE.path} element={<UserProfile/>}/>
                        <Route path={USER_DASHBOARD_ROUTES.USER.INDEX.path} element={<UserIndex/>}>
                            <Route path={USER_DASHBOARD_ROUTES.USER.SETTINGS.path} element={<UserSettings/>} />
                            <Route path={USER_DASHBOARD_ROUTES.USER.WEBSITE.path} element={<SiteSettings/>} />
                        </Route>
                        <Route path={USER_DASHBOARD_ROUTES.USER.ACTIVITY.NOTIFICATIONS.INDEX.path} element={<NotificationsIndex/>} />
                        {/* Tags */}

                        {/* Stories */}
                        <Route path={USER_DASHBOARD_ROUTES.STORIES.INDEX.path} element={<StoryIndex/>}>
                            <Route path={USER_DASHBOARD_ROUTES.STORIES.DRAFTS.path} element={<StoryDraftsIndex/>} />
                            <Route path={USER_DASHBOARD_ROUTES.STORIES.PUBLIC.path} element={<StoryPublishedIndex/>} />
                            <Route path={USER_DASHBOARD_ROUTES.STORIES.RESPONSES.INDEX.path} element={<ResponsesIndex/>} />
                        </Route>
                        <Route path={USER_DASHBOARD_ROUTES.STORIES.SHOW.path} element={<StoryShow/>} />

                        {/* Responses */}
                        <Route path={UserDashboardRoutes().STORIES.RESPONSES.SHOW.path} element={<ResponseShow/>} />

                    </Route>
                    
                    <Route element={<LayoutDashboardClean/>}>

                        {/* Stories */}
                        <Route path={UserDashboardRoutes().STORIES.CREATE.path} element={<StoryCreate/>} />
                        <Route path={UserDashboardRoutes().STORIES.UPDATE.path} element={<StoryUpdate/>} />

                    </Route>

                    <Route element={<LayoutDashboardStats/>}>

                        {/* Statistics */}
                        <Route path={UserDashboardRoutes().STATS.INDEX.path} element={<StatsIndex/>} />

                    </Route>

                </Route>

                {/* 404 page */}
                <Route path="*" element={<NotFound />} />

            </Routes>

        </>
    )
}

export default WebRoutes
