import { Link } from "react-router-dom"
import Links from "../../../components/links/footer-links/links1/Links"
import RecommendedTags from "../../../components/tags/recommended/RecommendedTags"
import { UserDashboardRoutes } from "../../../routes/RouteNames"
import RecommendedStories from "../../../components/stories/RecommendedStories"


const Sidebar = () => {

    return (
        <div className='flex flex-col justify-between h-full'>

            <div className="mt-[40px]">

                <div className="mb-5">
                    <Link to=''>
                        <h2 className="text-primary font-semibold">Picked for you</h2>
                    </Link>
                </div>

                <div className="mb-4">

                    <RecommendedStories />

                </div>

                <div className="">
                    <Link to={UserDashboardRoutes().STORIES.PUBLIC.nav()} className="text-third hover:text-[#191919] text-xs">
                        See the full list
                    </Link>
                </div>

                <div className="mt-[40px]">

                    <div className="mb-5">
                        <h2 className="text-primary font-semibold">Recommended topics</h2>
                    </div>

                    <div className="mb-4">
                        <RecommendedTags />
                    </div>

                    {/* <div className="">
                        <Link to={UserDashboardRoutes().TAGS.INDEX.nav()} className="text-third hover:text-[#191919] text-xs">
                            See more topics
                        </Link>
                    </div> */}

                </div>

            </div>

            <div className="py-[24px]">

                <Links />

            </div>

        </div>
    )
}

export default Sidebar
