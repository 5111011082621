import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { app } from '../../helpers/app'
import { changeDocumentTitle, _GET } from '../../helpers/helpers'
import useAlert from '../../hooks/useAlert'
import usePost from '../../hooks/usePost'
import useFetch from '../../hooks/useFetch'
import AlertSeverity from '../alerts/AlertSeverity'
import Button from '../buttons/button1/Button'
// import Input from '../inputs/input1/Input'
import LinkInfo from '../links/LinkInfo'
import { DashboardRoutes } from '../../routes/RouteNames'
import AuthCode from 'react-auth-code-input'



function VerifyEmail() {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Email verification')
    const navigate = useNavigate()

    const AuthInputRef = useRef(null)
    // const FormRef = useRef(null)
    const user_id = _GET('id')
    const [code, setCode] = useState('')
    const { response, isLoading, error, post } = usePost()
    const { response: fetchRes, isLoading: fetchIsLoading, error: fetchError, fetch } = useFetch()
    const { type: alertType, visible: alertVisible, message: alertMessage, show: showAlert, hide: hideAlert } = useAlert()
  
    useEffect(() => {
        hideAlert()

        if ( !user_id || user_id == undefined ) navigate(DashboardRoutes().LOGIN.nav)

        if (error) {
            showAlert('danger', error.message)
            resetForm()
        }

        if (response) {
            showAlert('success', "Your email has been verified! You can now login.")
            setTimeout(() => {
                navigate(DashboardRoutes().LOGIN.nav)
            }, 2 * 1000)
        }

    }, [error, response, user_id])
    
    useEffect(() => {

        if (fetchError) {
            showAlert('danger', error.message)
        }
  
        if (fetchRes) {
            
            showAlert('success', "New verification code has been sent to your email!")
        }

    }, [fetchError, fetchRes])

    useEffect(() => {

        if ( !code ) return

        submit()

    }, [code])

    const handleSubmit = async (e) => {
        e.preventDefault()

        hideAlert()
        // check inputs
        if (!code) {
            showAlert('danger', 'Please enter the verification code.')
            return
        }

        post('/verifyEmail', {
            user_id: user_id,
            code,
        }, {
            'X-TOKEN': app().TOKEN
        })
    }

    const submit = () => {
        hideAlert()
        // check inputs
        if (!code) {
            showAlert('danger', 'Please enter the verification code.')
            return
        }

        post('/verifyEmail', {
            user_id: user_id,
            code,
        }, {
            'X-TOKEN': app().TOKEN
        })
    }

    const resetForm = () => {
        setCode('')
        AuthInputRef.current.clear()
    }

    const code__onChange = (value) => {
        if ( value.length == 6 )
        {
            setCode(value.toUpperCase())
        }
    }

    const requestCode__onClick = (e) => {
        fetch(`/requestVerificationCode/${user_id}`, {}, {
            'X-TOKEN': app().TOKEN
        })
    }

    return (
        <div className='flex items-center justify-center h-full'>

            <div className="text-center max-w-[678px] w-full mx-auto px-[56px] py-[44px]">

                <h2 className="text-[28px] leading-8 mb-8">Verify you account</h2>

                <p className='max-w-sm mx-auto font-light'>
                    Your email must be verified before you can login.
                </p>

                <div className="my-3">
                    <AlertSeverity type={alertType} visible={alertVisible} message={alertMessage} onClickHide={hideAlert} />
                </div>

                <div className="inline-block min-w-[270px] mt-12">

                    <form onSubmit={handleSubmit}>

                        <div className="mb-7 border-b border-slate-950">
                            <label htmlFor="" className='text-[13px] mb-5 block'>Type-in your verification code</label>
                            {/* <Input value={code} onChange={(e) => setCode(e.target.value)} /> */}
                            <div className="auth-code pb-5">
                                <AuthCode inputClassName='auth-input' onChange={code__onChange} length={6} autoFocus ref={AuthInputRef}/>
                            </div>
                        </div>

                        <div className="mt-10">
                            <Button isLoaderVisible={isLoading} disabled={isLoading} text={'Verify my account'} />
                        </div>

                        <div className="mt-5">
                            <span className='inline-block pr-2 text-primary'>Didn't receive the code?</span>
                            <LinkInfo onClick={requestCode__onClick} disabled={fetchIsLoading}>
                                Request new one
                            </LinkInfo>
                        </div>

                    </form>



                </div>

            </div>

        </div>
    )
}

export default VerifyEmail
