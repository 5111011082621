import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { app } from '../../helpers/app'
import { changeDocumentTitle } from '../../helpers/helpers'
import useAlert from '../../hooks/useAlert'
import useAuth from '../../hooks/useAuth'
import usePost from '../../hooks/usePost'
import { UserDashboardRoutes, DashboardRoutes } from '../../routes/RouteNames'
import AlertSeverity from '../alerts/AlertSeverity'
import Button from '../buttons/button1/Button'
import Input from '../inputs/input1/Input'
import Loader from '../loaders/loader1/Loader'

function Login() {

    // change page title
    const PAGE_TITLE = changeDocumentTitle('Login')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { response, isLoading, error, post } = usePost()
    const { type: alertType, visible: alertVisible, message: alertMessage, show: showAlert, hide: hideAlert } = useAlert()
    const {login} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        hideAlert()

        if (error) {
            showAlert('danger', error.message)
            console.log(error)
        }

        if (response) {
            const data = response.data
            login(data)
            if ( data.role.code == 'USER' ) // go to user dashboard
            {
                navigate(UserDashboardRoutes().USER.PROFILE.nav(data.username))
            }
            else if ( data.role.code == 'ADMIN' ) // go to admin dashboard
            {

            }

            resetForm()
        }

    }, [error, response])

    const handleSubmit = async (e) => {
        e.preventDefault()

        hideAlert()
        // check inputs
        if (!email) {
            showAlert('danger', 'Please enter your email.')
            return
        }

        if (!password) {
            showAlert('danger', 'Please enter your password.')
            return
        }

        post('/login', {
            email,
            password,
        }, {
            'X-TOKEN': app().TOKEN
        })
    }

    const resetForm = () => {
        setEmail('')
        setPassword('')
    }

    return (
        <div className='flex items-center justify-center h-full'>

            <div className="text-center max-w-[678px] w-full mx-auto px-[56px] py-[44px]">

                <h2 className="text-[28px] leading-8 mb-8">Sign in with email</h2>

                <p className='max-w-sm mx-auto font-light'>
                    Enter the email address associated with your account, and your password.
                </p>

                <div className="my-3">
                    <AlertSeverity type={alertType} visible={alertVisible} message={alertMessage} onClickHide={hideAlert} />
                </div>

                <div className="inline-block min-w-[270px] mt-12">

                    <form onSubmit={handleSubmit}>

                        <div className="mb-7 border-b border-slate-950">
                            <label htmlFor="" className='text-[13px] mb-5 block'>Your email</label>
                            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="mb-7 border-b border-slate-950">
                            <label htmlFor="" className='text-[13px] mb-5 block'>Your secret password</label>
                            <Input type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        <div className="mt-10">
                            <Button isLoaderVisible={isLoading} disabled={isLoading} />
                        </div>

                        <div className="mt-5">
                            <span className='inline-block pr-2 text-primary'>No account?</span>
                            <Link to={DashboardRoutes().SIGNUP.nav} className={'text-sixth font-bold'}>
                                Create one
                            </Link>
                        </div>

                    </form>



                </div>

            </div>

        </div>
    )
}

export default Login
