import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import UserPlaceholder from '../../assets/img/male.png'
import { summarizeText } from "../../helpers/helpers"
import useFetch from "../../hooks/useFetch"
import Placeholder from "../placeholders/placeholder1/Placeholder"
import {UserDashboardRoutes} from '../../routes/RouteNames'
import useAuth from "../../hooks/useAuth"
const RecommendedStories = ({ total = 4 }) => {
    const { Auth } = useAuth()
    const [stories, setStories] = useState()
    const { response, error, isLoading, fetch } = useFetch()
    
    useEffect(() => {

        if (response) {
            setStories(response.data)
        }

    }, [response, error])

    useEffect(() => {
        fetch(`/dashboard/stories/random/${total}/${Auth().user.id}`)
    }, [])

    return (
        <div>

            {
                isLoading && (
                    Array.from(['', '', '', '']).map((v, i) => {
                        return (
                            <div className="mb-6" key={i}>

                                <div className="mb-2">
                                    <div>
                                        <div className="flex items-center">
                                            <div className="">
                                                <Placeholder className={'h-5 w-5 rounded-full'} />
                                            </div>
                                            <div className="w-2"></div>
                                            <div className="flex text-xs">
                                                <div className="font-semibold">
                                                    <Placeholder className={'h-5 w-20 !rounded-sm'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div>
                                        <h2 className="font-bold text-sm">
                                            <Placeholder className={'h-7 !rounded-sm'} />
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                )
            }

            {
                stories && stories.map((story, storyIndex) => {
                    return (
                        <div className="mb-6" key={story.id}>

                            <div className="mb-2">
                                <Link to={UserDashboardRoutes().STORIES.SHOW.nav(story.slug)}>
                                    <div className="flex items-center">
                                        <div className="">
                                            <img src={(story.author.avatar) ? story.author.avatar.url : UserPlaceholder} className="w-5 h-5 rounded-full" alt="" />
                                        </div>
                                        <div className="w-2"></div>
                                        <div className="flex text-xs">
                                            <div className="font-semibold">
                                                <h4 className="">{story.author.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="">
                                <Link to={UserDashboardRoutes().STORIES.SHOW.nav(story.slug)}>
                                    <h2 className="font-bold text-sm">
                                        {summarizeText(story.title, 60)}
                                    </h2>
                                </Link>
                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default RecommendedStories
