export const app = () =>
{
    return {
        NAME: 'Mr Abdennour',
        API_END_POINT: (window.location.host == 'localhost' || window.location.host == 'localhost:3000') ? 'http://127.0.0.1:8000/api' : 'https://pondstories.com/api',
        FRONT_END_URL: (window.location.host == 'localhost' || window.location.host == 'localhost:3000') ? 'http://127.0.0.1:8000' : 'https://pondstories.com',
        TOKEN: '2cbcbfba687f083e8989433fde75da23515d8574',
        GRECAPTCHA_V3: {
            SITE_KEY: '6Lc9SFIpAAAAABpMK3h4kfIQujazsHwTEh4K_Xxi',
        }
    }
}